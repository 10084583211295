export default class test {
  constructor() {
    this.input = {
      addressId: [],
      id: 0,
      name: "",
      cityId: "",
      scheduleId: "",
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.name = data.username;
      this.input.addressId = data.addressId;
      this.input.scheduleId = data.scheduleId;
      this.input.cityId = data.cityId;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].name,
              type: "string",
            },
            {
              id: 3,
              name:
                "С " +
                data[i].schedule.timeStart +
                " до " +
                data[i].schedule.timeEnd,
              type: "string",
            },
            {
              id: 4,
              name: data[i].address.street + " " + data[i].address.house,
              type: "string",
            },
            {
              id: 7,
              name: data[i].id,
              type: "redirect",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
