<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="superAdmin"
      :delet="superAdmin"
      :limits="superAdmin"
      :button-show="superAdmin"
      @save-button="saveButton()"
      @delete-button="deleteButton"
    ></table-component>
  </div>
</template>
<script>
import TableComponent from "@/components/TableComponent";
import Loader from "@/components/Loader";
import department from "@/model/department.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      superAdmin: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Название" },
          { id: 3, name: "Расписание" },
          { id: 4, name: "Адрес" },
          { id: 7, name: "Профиль" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
    }),
  },
  async mounted() {
    if (this.USER.userInfo.user.roleId === 5) {
      this.superAdmin = false;
    }
    this.checkPower();
    this.setAll();
  },
  methods: {
    ...mapActions({
      getDepartment: "Department/GET_DEPARTMENT",
      deleteDepartment: "Department/DELETE_DEPARTMENT",
    }),
    async setAll() {
      this.loading = true;
      await this.getDepartment(this.$root.city);
      let model = new department();
      this.items.body = model.setBody(this.DEPARTMENT.cityDepartment);
      this.loading = false;
    },
    checkPower() {
      this.loading = true;
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
      this.loading = false;
    },
    saveButton() {
      let model = new test();
      let result = model.setInput(this.input);
      this.items.body.push({
        id: this.items.body[this.items.body.length - 1].id + 1,
        fields: [
          { id: 1, name: result.one },
          { id: 2, name: result.two },
          { id: 3, name: result.three },
          { id: 4, name: result.four },
          { id: 5, name: result.five },
        ],
      });
    },
    async deleteButton(department) {
      let response = await this.deleteDepartment(department[0].id);
      if (response.type === "success") {
        this.$notify({
          group: "app",
          type: "success",
          title: `Успешно`,
          text: "Филиал удален",
        });
        this.setAll();
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: `Ошибка`,
          text: response.data.message,
        });
      }
    },
  },
};
</script>
